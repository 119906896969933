import React from "react";
import Layout from "../atoms/Layout/index";
import { useApi } from "../hooks/useApi";
import { useParams, useHistory } from "react-router";
import { Base64 } from "js-base64";
import CoilsForOrder from "../atoms/CoilsForOrder/CoilsForOrder";
import Error from "../atoms/Error";
import useAuthedOnly from '../hooks/useAuthedOnly';

function Lieferschein2() {
  //groups
  //sort
  //filter
  let authed = useAuthedOnly({});
  let history = useHistory();
  let params = useParams();
  let positionen = JSON.parse(Base64.decode(params.positionen));
  let [exec, status, result, error] = useApi(
    {
      path: "createls",
      authenticate: true,
    },
    (d) => {
      if (d.lieferscheine && d.lieferscheine.length > 0) {
        history.push(
          "/lsDownload/" + d.lieferscheine.map((l) => l.lsNr).join(",")
        );
      } else {
        //something went wrong..
      }
    }
  );

  return (
    <Layout title="Lieferschein erstellen">
      <CoilsForOrder
        showSubmit="Lieferschein erstellen"
        showSummary
        positionen={positionen}
        lock={true}
        status={status}
        checkMengen
        onSubmit={(d) => {
          exec(d);
        }}
      ></CoilsForOrder>
      {status == "error" && <Error>{error}</Error>}
    </Layout>
  );
}

export default Lieferschein2;
