import React, { useState } from "react";
import style from "./LockRemover.less";
import ActionLink from "../ActionLink/ActionLink";
import { useApi } from "../../hooks/useApi";
import { useHistory } from "react-router";
import Loading from "../Loading/Loading";
import Error from "../Error";
import Spacer from "../Spacer";

export default function LockRemover({ lock }) {
  //let history = useHistory();
  let [exec, status, result, error] = useApi(
    {
      path: "lscancel",
      authenticate: true,
    },
    () => {
      window.location.reload();
    }
  );
  return (
    <div styleName="LockRemover">
      {status == "loading" && <Loading></Loading>}
      {status == "start" && (
        <div>
          <Error>
            Diese Positionen sind bereits durch eine vorherige Anfrage gesperrt.
          </Error>
          <Spacer h={12}></Spacer>
          Warten Sie 5 Minuten, oder klicken Sie{" "}
          <ActionLink onClick={() => exec({ reqId: lock })}>hier</ActionLink>,
          um die Sperre aufzuheben.
        </div>
      )}
    </div>
  );
}
