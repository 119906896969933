import React, { useState } from "react";
import style from "./Loading.less";
import L from "react-loading";
function Loading({ children, color = "white" }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        color: color
      }}
    >
      <L width={18} height={18} color={color} type="spin"></L>
    </div>
  );
}

//Connect to navigation, redux and export
export default Loading;
