import React, { useState } from "react";
import style from "./AuftragsInput.less";
import Button from "../Button/Button";
import RoundButton from "../RoundButton/RoundButton";
import Spacer from "../Spacer";
import { useAutofocus } from "../../hooks/useAutofocus";

export default function AuftragsInput({
  onFinish = () => {},
  buttonText = "Coils abrufen",
}) {
  let [rows, setRows] = useState(1);
  let [positions, setPositions] = useState([]);
  let addRow = () => {
    setRows(rows + 1);
  };

  let updatePosition = (id, value) => {
    let c = positions.slice();
    c[id] = value;
    setPositions(c);
  };

  return (
    <div styleName="AuftragsInput">
      {Array(rows)
        .fill(0)
        .map((v, i) => {
          v = {
            auftragId: positions[i] ? positions[i].auftragId : "",
            auftragPos: positions[i] ? positions[i].auftragPos : "",
          };
          return (
            <div styleName="auftragRow" key={i}>
              <AuftragIdInput
                onChange={(t) => updatePosition(i, { ...v, auftragId: t })}
                value={v.auftragId}
              ></AuftragIdInput>
              <PositionInput
                onEnter={() => {
                  addRow();
                }}
                type="text"
                styleName="position"
                onChange={(e) => {
                  updatePosition(i, { ...v, auftragPos: e.target.value });
                }}
                value={v.auftragPos}
                placeholder={"Position"}
              />
            </div>
          );
        })}
      <div styleName="auftragRow">
        <div
          style={{
            display: "flex",
            maxWidth: 370,
            flex: 1,
            margin: "0 auto",
            justifyContent: "flex-end",
          }}
        >
          <RoundButton
            onClick={() => {
              addRow();
            }}
          ></RoundButton>
        </div>
      </div>
      <Spacer h={8}></Spacer>
      <div styleName="buttonWrapper">
        <Button
          onClick={() => {
            onFinish(
              positions.map((p) => ({
                auftragId: Number(p.auftragId),
                auftragPos: Number(p.auftragPos),
              }))
            );
          }}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

function AuftragIdInput({ value, onChange }) {
  let auto = useAutofocus();
  return (
    <input
      ref={auto}
      type="text"
      styleName="auftrag"
      placeholder={"Auftrag"}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    ></input>
  );
}

function PositionInput({ onChange, value, onEnter }) {
  return (
    <input
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onEnter && onEnter();
        }
      }}
      type="text"
      styleName="position"
      onChange={onChange}
      value={value}
      placeholder={"Position"}
    ></input>
  );
}
