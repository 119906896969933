import React, { useState, useEffect } from "react";
import style from "./CoilsForOrder.less";

import SuperTable from "../SuperTable/SuperTable";
import Button from "../Button/Button";
import Loading from "../Loading/Loading";
import { blue } from "../../lib";
import Title from "../Title";
import Spacer from "../Spacer";
import { useApi } from "../../hooks/useApi";
import ActionLink from "../ActionLink/ActionLink";
import LockRemover from "../LockRemover/LockRemover";
import Countdown from "../Countdown/Countdown";
import Error from "../Error/Error";
import TextInput from "../TextInput";
import { MdLinkedCamera } from "react-icons/md";
import { format } from "date-fns";

function makeAuftragId(p) {
  return p.auftragId + "-" + p.auftragPos;
}

function CoilsForOrder({
  positionen,
  lock,
  checkMengen,
  showSummary,
  showSubmit,
  onSubmit,
  status: saveStatus,
}) {
  //groups
  //sort
  //filter
  let [selectedOrders, setSelectedOrders] = useState(
    positionen.map(makeAuftragId)
  );

  let [exec, status, result, error] = useApi({
    path: "lsdaten",
    authenticate: true,
  });

  let [spediteur, setSpediteur] = useState("");
  let [kennzeichen, setKennzeichen] = useState("");
  let [lieferDatum, setLieferDatum] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  useEffect(() => {
    exec({ positionen, sperre: lock });
  }, []);
  let d = (result && result.lsData) || null;

  let selectedObjects = null;
  if (d) {
    selectedObjects = selectedOrders
      .map((os) => {
        let id = os.split("-")[0];
        let pos = os.split("-")[1];
        return d.find((r) => r.auftragId == id && r.auftragPos == pos);
      })
      .filter((o) => o);
  }
  let [selectedCoils, setSelectedCoils] = useState([]);
  //result:
  /*
	"auftragId" : 1, 
	"auftragPos" : 1,
	"mengeId" : 11111, 
	"planPosId" : 27,
	"clItemId": 1
	 */
  let summary = {};
  let lsResult = selectedCoils.map((cs) => {
    let a = cs.split("-");
    let clItemId = a[0];
    let auftragId = a[1];
    let auftragPos = a[2];
    //find auftrag:
    let o = d.find(
      (e) => e.auftragId == auftragId && e.auftragPos == auftragPos
    );
    //find coil:
    let c = o.coils.find((c) => c.clItemId == clItemId);
    summary[makeAuftragId(o)] = [
      ...(summary[makeAuftragId(o)] || []),
      { order: o, coil: c },
    ];
    return {
      auftragId: o.auftragId,
      auftragPos: o.auftragPos,
      mengeId: c.mengeId,
      planPosId: c.planPosId,
      clItemId: c.clItemId,
      spediteur,
      kennzeichen,
      lieferDatum,
    };
  });

  summary = Object.values(summary);

  //new summary:
  //for each selected coil, add own weight to menge?
  //check all aufträge:
  let checkErrors = [];
  let planPositions = {};
  if (checkMengen) {
    summary.flat().map((c) => {
      if (planPositions[c.coil.planPosId]) {
        planPositions[c.coil.planPosId].gewichtTotal += c.coil.nettogewicht;
      } else {
        planPositions[c.coil.planPosId] = {
          planPosId: c.coil.planPosId,
          mengeId: c.coil.mengeId,
          planPosGewicht: c.coil.planPosGewicht,
          mengeFreiesGewicht: c.coil.mengeFreiesGewicht,
          gewichtTotal: c.coil.nettogewicht,
        };
      }
    });
    let mengen = {};
    //für jede menge, check wie viel insgesamt genommen wurde. wie viel insgesamt für diese anfrage geplant wurde.
    Object.values(planPositions).map((d) => {
      if (mengen[d.mengeId]) {
        mengen[d.mengeId].totalPlan += d.planPosGewicht;
        mengen[d.mengeId].totalUsed += d.gewichtTotal;
      } else {
        mengen[d.mengeId] = {
          mengeId: d.mengeId,
          totalPlan: d.planPosGewicht,
          totalUsed: d.gewichtTotal,
          free: d.mengeFreiesGewicht,
        };
      }
    });
    Object.values(mengen).map((m) => {
      if (m.totalUsed - m.totalPlan > m.free) {
        checkErrors.push(m.mengeId);
      }
    });
  }

  if (status == "error") {
    if (error && error.startsWith("Lock already exists")) {
      return (
        <LockRemover lock={Number(error.split("Req_ID: ")[1])}></LockRemover>
      );
    }
    return <Error>{error}</Error>;
  }
  if (status === "done" && (!d || !d.length)) {
    return <Error>Zu Ihrer Suche gibt es keine Ergebnisse.</Error>;
  }

  return (
    <div>
      {result && result.maxMinutesLocked && (
        <>
          <Spacer h={4}></Spacer>
          <Countdown
            minutes={result.maxMinutesLocked}
            onFinish={() => {
              alert(
                "Die Sperrzeit ist abgelaufen. Bitte starten Sie den Vorgang erneut."
              );
            }}
          ></Countdown>
        </>
      )}
      {d && d.length < positionen.length && (
        <Error big>
          Sie haben nach {positionen.length} Positionen gesucht, aber es wurde
          {d.length == 1 ? "" : "n"} nur {d.length} Position
          {d.length == 1 ? "" : "en"} gefunden.
        </Error>
      )}
      <h1>Positionen</h1>
      {status == "done" && d && (
        <SuperTable
          value={selectedOrders}
          onChange={setSelectedOrders}
          fields={[
            { name: "Kunde", field: "kunde", sort: false },
            { name: "Auftrag", field: "auftragId", sort: false },
            { name: "Position", field: "auftragPos", sort: false },
            { name: "Dicke", field: "aftDicke", sort: false, seperator: true },
            {
              name: "Breite",
              field: "aftBreite",
              sort: false,
              seperator: true,
            },
            { name: "Länge", field: "aftLaenge", sort: false, seperator: true },
            { name: "Güte", field: "aftGuete", sort: false },
            { name: "Oberfläche", field: "aftOberflaeche", sort: false },
          ]}
          keyExtractor={(i) => i.auftragId + "-" + i.auftragPos}
          showSummary
          data={d || []}
        ></SuperTable>
      )}
      {selectedObjects &&
        selectedObjects.map((o, i) => {
          //for each selected coil that's not in this position, disable coilNumber.
          let disabled = selectedCoils
            .filter((c) => !c.endsWith(o.auftragId + "-" + o.auftragPos))
            .map((c) => {
              let a = c.split("-");
              return a[0] + "-" + o.auftragId + "-" + o.auftragPos;
            });
          return (
            <React.Fragment key={i}>
              <Spacer h={24}></Spacer>
              <h1 styleName="breakBefore">
                Auftrag: {o.auftragId}, Position: {o.auftragPos}
              </h1>
              <SuperTable
                key={o.auftragId + "-" + o.auftragPos}
                keyExtractor={(i) =>
                  i.clItemId + "-" + o.auftragId + "-" + o.auftragPos
                }
                fields={[
                  { name: "Coil Nr.", field: "coilNummer" },
                  { name: "Dicke", field: "mngDicke", seperator: true },
                  { name: "Breite", field: "mngBreite", seperator: true },
                  {
                    name: "Netto Gew.",
                    field: "nettogewicht",
                    summable: true,
                    summary: true,
                    seperator: true,
                  },
                  {
                    name: "Brutto Gew.",
                    field: "bruttogewicht",
                    summable: true,
                    summary: true,
                    seperator: true,
                  },
                  { name: "Länge", field: "coilLaenge", seperator: true },
                ]}
                value={selectedCoils}
                disabled={disabled}
                onChange={setSelectedCoils}
                data={o.coils}
                showSummary
              ></SuperTable>
            </React.Fragment>
          );
        })}
      {selectedCoils && selectedCoils.length > 0 && (
        <>
          <Spacer h={4}></Spacer>
          <ActionLink
            onClick={() => {
              setSelectedCoils([]);
            }}
          >
            Auswahl zurücksetzen
          </ActionLink>
          <Spacer h={8}></Spacer>
        </>
      )}
      {showSummary && selectedCoils.length > 0 && (
        <div styleName="breakBefore flex">
          <div>
            <h1>Zusammenfassung</h1>
            <Spacer h={12}></Spacer>
            {selectedCoils.length} Coil{selectedCoils.length > 1 && "s"}{" "}
            ausgewählt
            <Spacer h={12}></Spacer>
            {summary.map((o, i) => {
              let count = o.length;
              let sumBrutto = o
                .map((c) => {
                  return c.coil.bruttogewicht;
                })
                .reduce((a, b) => a + b);

              let sumNetto = o
                .map((c) => {
                  return c.coil.nettogewicht;
                })
                .reduce((a, b) => a + b);

              return (
                <div key={i} styleName="summaryPosition">
                  <h1>
                    Auftrag: {o[0].order.auftragId}, Position:{" "}
                    {o[0].order.auftragPos}
                  </h1>
                  <b>{count} Coils</b>
                  <br />
                  Brutto: <b>{sumBrutto.toLocaleString()}</b>
                  <br />
                  Netto: <b>{sumNetto.toLocaleString()}</b>
                  <br />
                </div>
              );
            })}
          </div>
          <div>
            <h1>Weitere Infos</h1>
            <Spacer h={12}></Spacer>
            <TextInput
              value={lieferDatum}
              type="date"
              onChange={setLieferDatum}
              title="Lieferdatum"
              onSubmit={() => {}}
              placeholder={"22.10.2021"}
            ></TextInput>
            <TextInput
              value={spediteur}
              onChange={setSpediteur}
              title="Name der Spedition"
              onSubmit={() => {}}
              placeholder={"Spediteur"}
            ></TextInput>
            <TextInput
              value={kennzeichen}
              onChange={setKennzeichen}
              title="Kennzeichen"
              onSubmit={() => {}}
              placeholder={"XX-YY-1234"}
            ></TextInput>
          </div>
        </div>
      )}

      {checkMengen && checkErrors.length > 0 && (
        <>
          <Error big>Ungültige Auswahl!</Error>

          <Error big>Ihre Auswahl überschreitet die zugewiesene Menge.</Error>
        </>
      )}
      {showSubmit && selectedCoils.length > 0 && (
        <div style={{ display: "flex" }}>
          <Button
            status={saveStatus}
            onClick={() => onSubmit({ auswahl: lsResult, reqId: result.reqId })}
          >
            {showSubmit}
          </Button>
        </div>
      )}
      {/*
		<Spacer h={24}></Spacer>
		<h1>Zugehörige Coils</h1>
		<SuperTable
		  key={selectedPositions}
		  fields={[
			{ name: "MENGE_ID", field: "MENGE_ID" },
			{ name: "PLAN_POS_ID", field: "PLAN_POS_ID" },
			{ name: "Dicke", field: "dicke" },
			{ name: "Breite", field: "breite" },
			{ name: "Coil Nr.", field: "coilnr" },
			{ name: "Netto Gew.", field: "netto" },
			{ name: "Brutto Gew.", field: "brutto" },
			{ name: "Länge", field: "länge" },
		  ]}
		  value={selected}
		  cluster={"position"}
		  showSummary
		  onChange={setSelected}
		  data={coils.filter((i) => selectedPositions.includes(i.position))}
		></SuperTable>
	 
  */}
    </div>
  );
}

export default CoilsForOrder;
