import React from "react";
import Layout from "../atoms/Layout/index";
import { blue } from "../lib";
import Spacer from "../atoms/Spacer";
import "./Home.less";
import { FaTruck, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import useAuthedOnly from "../hooks/useAuthedOnly";
import useUser from "../hooks/useUser";
function Home() {
  let authed = useAuthedOnly({});
  let user = useUser();
  //roles:
  // 1 = Logistiker
  // 2 = Kunde
  // 3 Coilinter

  return (
    <Layout title="Home">
      <Spacer h={8}></Spacer>
      <b>
        Willkommen bei OLA, dem Online Lieferschein Assistenten von Coilinter.
      </b>
      <Spacer h={24}></Spacer>
      <div styleName="gridRow">
        <div styleName="gridItem">
          <div styleName="icon">
            <FaTruck size={60} color={blue}></FaTruck>
          </div>

          {[1, 3].includes(user.roleId) && user.lagerId && (
            <Link to="lieferschein" styleName="name">
              Lieferschein erstellen
            </Link>
          )}
          {[1, 2, 3].includes(user.roleId) && (
            <Link to="lieferscheine" styleName="name">
              Lieferscheine abrufen
            </Link>
          )}
        </div>
        <div styleName="gridItem">
          <div styleName="icon">
            <FaSearch size={60} color={blue} />
          </div>
          {[1, 2, 3].includes(user.roleId) && (
            <Link to="coils" styleName="name ">
              Coils abrufen
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Home;
