//Reducers: Manages data, state
import createReducer from '../lib/createReducer';
import * as types from '../actions/types';

//Define name and default value
export const user = createReducer(null, {
  [types.setUserObject](state, action) {
    return {...action.payload};
  },
});
