import React, { useState } from "react";
import style from "./TextInput.less";

function TextInput({
  title = "Input",
  value = "",
  type = "text",
  placeholder = "",
  useAutofocus,
  onChange = () => {},
  onSubmit,
}) {
  return (
    <>
      <label styleName={"Label"}>{title}</label>
      <input
        ref={useAutofocus}
        onChange={(e) => onChange(e.target.value)}
        type={type}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            onSubmit && onSubmit();
          }
        }}
        value={value}
        styleName={"TextInput"}
      ></input>
    </>
  );
}

//Connect to navigation, redux and export
export default TextInput;
