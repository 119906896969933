import React, { useState, useEffect, useCallback } from "react";
import style from "./Countdown.less";

export default function Countdown({ minutes = 0, seconds = 0, onFinish }) {
  const total = minutes * 60 + seconds;
  const [timeLeft, setTimeLeft] = useState(total);

  useEffect(() => {
    if (timeLeft === 0) {
      onFinish();
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <div styleName="Countdown">
      <div
        styleName="inner"
        style={{ width: (timeLeft / total) * 100 + "%" }}
      ></div>
      <div styleName="text">
        Gültigkeit: {Math.floor(timeLeft / 60)}:
        {(timeLeft % 60).toString().padStart(2, "0")}
      </div>
    </div>
  );
}
