import React from "react";
import style from "./AppContainer.less";
import "./Global.less";

import { connect } from "react-redux";
import { ActionCreators } from "redux/actions";
import { bindActionCreators } from "redux";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "../../screens/Home";
import Login from "../../screens/Login";
import Coilrechner from "../../screens/Coilrechner";
import Lieferschein1 from "../../screens/Lieferschein1";
import Lieferschein2 from "../../screens/Lieferschein2";
import Coils1 from "../../screens/Coils1";
import Coils2 from "../../screens/Coils2";
import LSAbrufen1 from "../../screens/LSAbrufen1";
import LSAbrufen2 from "../../screens/LSAbrufen2";
import Debug from "../../screens/Debug";
import LieferscheinDownload from "../../screens/LieferscheinDownload";
import Splash from '../../screens/Splash';

class AppContainer extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/coils/:positionen">
            <Coils2></Coils2>
          </Route>
          <Route path="/coils">
            <Coils1></Coils1>
          </Route>
          <Route path="/lieferschein/:positionen">
            <Lieferschein2></Lieferschein2>
          </Route>
          <Route path="/lsDownload/:lsNrs">
            <LieferscheinDownload></LieferscheinDownload>
          </Route>
          <Route path="/lieferschein">
            <Lieferschein1></Lieferschein1>
          </Route>

          <Route path="/lieferscheine/:auftrag">
            <LSAbrufen2></LSAbrufen2>
          </Route>

          <Route path="/lieferscheine">
            <LSAbrufen1></LSAbrufen1>
          </Route>
          <Route path="/debug">
            <Debug></Debug>
          </Route>
          <Route path="/home">
            <Home></Home>
          </Route>
          <Route path="/coilrechner">
            <Coilrechner></Coilrechner>
          </Route>
          <Route path="/login">
            <Login></Login>
          </Route>
          <Route path="/">
            <Splash></Splash>
          </Route>
        </Switch>
      </Router>
    );
  }
}

//Make state available as props
function mapStateToProps(state) {
  return {};
}

//Make actions available as functions in props
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

//Connect to navigation, redux and export
export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
