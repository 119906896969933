import React from "react";
import Layout from "../atoms/Layout";
import { useParams } from "react-router";
import LieferscheinDownloader from "../atoms/LieferscheinDownloader/LieferscheinDownloader";
import Spacer from "../atoms/Spacer";
import useAuthedOnly from '../hooks/useAuthedOnly';

const LieferscheinDownload = () => {
  let authed = useAuthedOnly({});
  let { lsNrs } = useParams();
  lsNrs = lsNrs.split(",");

  return (
    <Layout title="Lieferscheie herunterladen" width={900}>
      <Spacer h={8}></Spacer>
      <h1>Ihre Lieferscheine wurden erfolgreich erstellt</h1>
      <Spacer h={12}></Spacer>
      {lsNrs.map((lsNr) => {
        return <LieferscheinDownloader ls={lsNr}></LieferscheinDownloader>;
      })}
    </Layout>
  );
};

export default LieferscheinDownload;
