import React, { useState } from "react";
import Layout from "../atoms/Layout";
import AuftragsInput from "../atoms/AuftragsInput/AuftragsInput";
import { useHistory } from "react-router";
import { Base64 } from "js-base64";
import Note from "../atoms/Note/Note";
import TextInput from "../atoms/TextInput/TextInput";
import Button from "../atoms/Button/Button";
import { useAutofocus } from "../hooks/useAutofocus";
import useAuthedOnly from '../hooks/useAuthedOnly';

const AuftragStep1 = () => {
  let [auftrag, setAuftrag] = useState("");
  let history = useHistory();
  let goTo2 = () => {
    if (auftrag.length > 0) {
      history.push("/lieferscheine/" + auftrag);
    }
  };
  let auto = useAutofocus();
  let authed = useAuthedOnly({});
  return (
    <Layout title="Lieferscheine abrufen" width={900}>
      <div style={{ flexDirection: "column", display: "flex" }}>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            width: "100%",
            maxWidth: 350,
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          <TextInput
            useAutofocus={auto}
            value={auftrag}
            onChange={setAuftrag}
            title=""
            onSubmit={goTo2}
            placeholder={"Auftrags ID"}
          ></TextInput>
        </div>
        <Button onClick={goTo2}>Lieferscheine abrufen</Button>
      </div>
    </Layout>
  );
};

export default AuftragStep1;
