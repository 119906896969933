import React from "react";
import Layout from "../atoms/Layout/index";
import { blue } from "../lib";
import Spacer from "../atoms/Spacer";
import { FaTruck, FaSearch } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import L from "react-loading";
import useAuthedOnly from "../hooks/useAuthedOnly";

function Splash() {
  let history = useHistory();
  let authed = useAuthedOnly({
    authed: () => {
      history.push("/home");
    },
  });
  return (
    <Layout anon title="">
      <Spacer h={24}></Spacer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <L width={18} height={18} color={blue} type="spin"></L>
      </div>
    </Layout>
  );
}

export default Splash;
