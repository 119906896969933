import React, { useState } from "react";
import style from "./LieferscheinDownloader.less";
import Button from "../Button/Button";
import { useApi } from "../../hooks/useApi";
import fileDownload from "js-file-download";

export default function LieferscheinDownloader({ ls }) {
  let [exec, status, result, error] = useApi(
    {
      path: "downloadls?lsNr=" + ls,
      method: "GET",
      authenticate: true,
      repeat: true,
      responseType: "blob",
    },
    (data) => {
      fileDownload(data, "COILINTER_Lieferschein_" + ls + ".pdf");
    }
  );
  let dlLs = () => {
    exec({ lsNr: parseInt(ls) });
  };
  return (
    <div styleName="LieferscheinDownloader">
      <Button onClick={dlLs} status={status}>
        LS #{ls} herunterladen.
      </Button>
    </div>
  );
}
