import React, { useState } from "react";
import style from "./Nav.less";
import { MdMenu } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import {
  FaTimes,
  FaHome,
  FaArrowCircleLeft,
  FaFileInvoice,
  FaTruck,
  FaCog,
  FaCalculator,
  FaBackspace,
  FaRecycle,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import useUser from "../../hooks/useUser";

function Nav({ children }) {
  let [open, setOpen] = useState(false);
  let dispatch = useDispatch();
  let history = useHistory();
  let user = useUser();

  return (
    <div styleName={"Nav"}>
      <div onClick={() => setOpen(!open)} styleName={"menuButton"}>
        {open ? (
          <FaTimes size={20} color={"white"}></FaTimes>
        ) : (
          <MdMenu color={"white"} size={20}></MdMenu>
        )}
      </div>
      {open && (
        <div styleName={"floatMenu"}>
          {[1, 2, 3].includes(user.roleId) && (
            <NavItem path="/home" Icon={FaHome}>
              Startseite
            </NavItem>
          )}
          {[1, 2, 3].includes(user.roleId) && (
            <NavItem path="/coils" Icon={FaFileInvoice}>
              Coils abrufen
            </NavItem>
          )}
          {[1, 3].includes(user.roleId) && user.lagerId && (
            <NavItem path="/lieferschein" Icon={FaTruck}>
              Lieferschein erstellen
            </NavItem>
          )}
          {[1, 2, 3].includes(user.roleId) && (
            <NavItem path="/lieferscheine" Icon={FaRecycle}>
              Lieferscheine abrufen
            </NavItem>
          )}
          {/*
          <NavItem path="/coilrechner" Icon={FaCalculator}>
            Coilrechner
          </NavItem>
          <NavItem Icon={FaCog}>Einstellungen</NavItem>
          <NavItem path="/debug" Icon={FaCog}>
            Debug
          </NavItem>
          */}
          <NavItem
            onClick={() => {
              dispatch({ type: "setUserObject", payload: {} });
              history.push("/");
            }}
            Icon={FaArrowCircleLeft}
          >
            Ausloggen
          </NavItem>
        </div>
      )}
    </div>
  );
}

//Connect to navigation, redux and export
export default Nav;

function NavItem({ children = "", path = "/", onClick, Icon, disabled }) {
  return (
    <Link
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick();
          return false;
        }
      }}
      to={path}
      styleName={"navItem " + (disabled ? "disabled " : "")}
    >
      <div style={{ width: 4 }}></div>
      <Icon size={20}></Icon>
      <div style={{ width: 8 }}></div>
      {children}
    </Link>
  );
}
