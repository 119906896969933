import React, { useState } from "react";
import style from "./Layout.less";
import Title from "../Title/index";
import Nav from "../Nav/Nav";
import Spacer from "../Spacer/Spacer";
import { Link } from "react-router-dom";
import useUser from "../../hooks/useUser";
import Note from "../Note";
import LagerSelector from "../LagerSelector/LagerSelector";

function Layout({
  children,
  center,
  width = 1600,
  title,
  style = {},
  anon = false,
}) {
  let user = useUser() || {};
  return (
    <div styleName={"Layout"}>
      <div styleName={"Header"}>
        <Link to="/home">
          <img src="/img/logo.jpg"></img>
        </Link>
        <div style={{ flex: 1 }}></div>
        {user.roleId === 3 && (
          <>
            <LagerSelector></LagerSelector>
            <Spacer w={12}></Spacer>
          </>
        )}
        {!anon && user.username && (
          <div styleName="currentUser">
            Eingeloggt als
            <br />
            <b>{user.username}</b>
          </div>
        )}
        {!anon && user.token && <Nav></Nav>}
      </div>
      <Title
        style={{
          maxWidth: width,
        }}
      >
        {title}
      </Title>
      <div
        styleName={"Content"}
        style={{
          alignItems: center ? "center" : "none",
          maxWidth: width,
        }}
      >
        <div styleName={"Inner"} style={style}>
          {children}
          <Spacer h={24}></Spacer>
        </div>
      </div>
      <div styleName={"Footer"}>coilinter 2020</div>
    </div>
  );
}

//Connect to navigation, redux and export
export default Layout;
