import React, { useState, useEffect } from "react";
import Layout from "../atoms/Layout/Layout";
import TextInput from "../atoms/TextInput/TextInput";
import Spacer from "../atoms/Spacer/index";
import useAuthedOnly from '../hooks/useAuthedOnly';

function Coilrechner() {
  let authed = useAuthedOnly({});

  let [b, bSet] = useState("");
  let [id, idSet] = useState("");
  let [ad, adSet] = useState("");
  let [g, gSet] = useState("");

  let calc = (type) => {
    var konst = (7.85 / 4) * Math.PI;
    if (type == "g" && id.length > 0 && ad.length > 0 && b.length > 0) {
      let exakt =
        ((konst * b) / 1000) *
        ((parseFloat(ad) * parseFloat(ad)) / 1000000 -
          (parseFloat(id) * parseFloat(id)) / 1000000);
      let gerundet = Math.round(exakt * 100) / 100;
      gSet(gerundet.toString());
    } else if (type == "ad" && id.length > 0 && g.length > 0 && b.length > 0) {
      let exakt = Math.sqrt(
        (1000 * g * 1000000) / (konst * b) +
          1000000 * ((parseFloat(id) * parseFloat(id)) / 1000000)
      );
      let gerundet = Math.round(exakt * 100) / 100;
      adSet(gerundet.toString());
    }
  };

  useEffect(() => {
    calc("ad");
  }, [g, adSet]);
  useEffect(() => {
    calc("g");
  }, [ad, gSet]);

  return (
    <Layout title="Coilrechner">
      <div style={{ display: "flex" }}>
        <div>
          <img src="/img/coil.jpg"></img>
        </div>
        <Spacer w={24}></Spacer>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <TextInput
            type="number"
            onChange={bSet}
            value={b}
            title="Bandbreite (b)"
          ></TextInput>
          <TextInput
            type="number"
            onChange={idSet}
            value={id}
            title="Innendurchmesser (id)"
          ></TextInput>
          <TextInput
            type="number"
            onChange={adSet}
            value={ad}
            title="Außendurchmesser (ad)"
          ></TextInput>
          <TextInput
            type="number"
            onChange={gSet}
            value={g}
            title="Gewicht in t"
          ></TextInput>
        </div>
      </div>
    </Layout>
  );
}

export default Coilrechner;
