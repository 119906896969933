import React from "react";
import Layout from "../atoms/Layout/index";
import Button from "../atoms/Button/Button";
import Spacer from "../atoms/Spacer";
import { useParams } from "react-router";
import { Base64 } from "js-base64";
import CoilsForOrder from "../atoms/CoilsForOrder/CoilsForOrder";
import useAuthedOnly from '../hooks/useAuthedOnly';

function Coils2() {
  //groups
  //sort
  //filter
  let authed = useAuthedOnly({});
  let params = useParams();
  let positionen = JSON.parse(Base64.decode(params.positionen));
  return (
    <Layout title="Coils abrufen">
      <CoilsForOrder
        positionen={positionen}
        lock={false}
        checkMengen
        showSummary
      ></CoilsForOrder>
      <Spacer h={12}></Spacer>
      <Button
        onClick={() => {
          window.print
            ? window.print()
            : alert(
                "Bitte Drucken Sie die Seite mit hilfe der Browsereigenen funktion."
              );
        }}
      >
        Drucken
      </Button>
    </Layout>
  );
}

export default Coils2;
