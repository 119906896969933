import React, { useState } from "react";
import "./Title.less";

function Title({ children, style = {} }) {
  return (
    <div styleName={"Title"} style={style}>
      <div styleName={"Inner"}>{children}</div>
    </div>
  );
}

//Connect to navigation, redux and export
export default Title;
