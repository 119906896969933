import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import useUser from "../../hooks/useUser";
import { setUserObject } from "../../redux/actions/user";
import Button from "../Button/Button";
import Error from "../Error/Error";
import Loading from "../Loading/Loading";

import style from "./LagerSelector.less";

export default function LagerSelector({}) {
  let [exec, status, result, error] = useApi({
    path: "getlagerliste",
    authenticate: true,
    method: "post",
  });

  let [changeLager, changeStatus] = useApi({
    path: "changeLager",
    authenticate: true,
    method: "post",
    repeat: true,
  });

  let dispatch = useDispatch();
  let setLager = (lager) => {
    if (!lager) {
      dispatch(setUserObject({ ...user, lagerId: null, lagerName: null }));
      changeLager({ lagerId: null });
    }
    //make api call
    else {
      let [lagerId, lagerName] = lager.split("|||");

      changeLager({ lagerId: parseInt(lagerId) }).then(() => {
        dispatch(
          setUserObject({ ...user, lagerName, lagerId: parseInt(lagerId) })
        );
      });
    }
  };
  let user = useUser();

  if (user.lagerName && user.lagerId) {
    return (
      <div styleName="selectedLager">
        Lager: <b>{user.lagerName}</b>
        <br></br>
        <Link
          to="#"
          onClick={() => {
            setLager(null);
          }}
        >
          Ändern
        </Link>
      </div>
    );
  }
  return (
    <div styleName="LagerSelector">
      {status == "start" && (
        <Button onClick={() => exec()}>Lager auswählen</Button>
      )}
      {status == "loading" && <Loading color="black"></Loading>}
      {(status == "error" || changeStatus == "error") && <Error>Fehler</Error>}
      {status == "done" && (
        <select
          onChange={(e) => {
            setLager(e.target.value);
          }}
        >
          <option value={""}>Kein Lager ausgewählt</option>
          {result &&
            result.map &&
            result.map((lager) => {
              return (
                <option
                  key={lager.lagerId}
                  value={lager.lagerId + "|||" + lager.lager}
                >
                  {lager.lagerId} | {lager.lager}
                </option>
              );
            })}
        </select>
      )}
    </div>
  );
}
