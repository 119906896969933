import React, { useState } from "react";
import Layout from "../atoms/Layout/index";
import TextInput from "../atoms/TextInput/TextInput";
import Button from "../atoms/Button/Button";
import { useApi } from "../hooks/useApi";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import Error from "../atoms/Error/index";
import Spacer from "../atoms/Spacer/Spacer";
import { useAutofocus } from "../hooks/useAutofocus";
function Login() {
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let dispatch = useDispatch();
  let history = useHistory();
  let auto = useAutofocus();
  let [exec, status, result, error] = useApi(
    { path: "authenticate", repeat: true },
    (r) => {
      dispatch({ type: "setUserObject", payload: r });
      //redirect to /home
      history.push("/home");
    }
  );
  return (
    <Layout
      anon
      width={400}
      title={"Log in"}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Spacer h={24}></Spacer>
      <TextInput
        useAutofocus={auto}
        title={"Nutzername"}
        value={username}
        onChange={setUsername}
        onSubmit={() => {
          exec({ username, password });
        }}
      ></TextInput>
      <TextInput
        title="Passwort"
        type="password"
        value={password}
        onChange={setPassword}
        onSubmit={() => {
          exec({ username, password });
        }}
      ></TextInput>
      {status == "error" && <Error>{error}</Error>}
      <Button status={status} onClick={() => exec({ username, password })}>
        Log in
      </Button>
    </Layout>
  );
}

export default Login;
