import React, { useEffect } from "react";
import Layout from "../atoms/Layout";
import { useParams } from "react-router";
import LieferscheinDownloader from "../atoms/LieferscheinDownloader/LieferscheinDownloader";
import { useApi } from "../hooks/useApi";
import "./LSAbrufen2.less";
import Spacer from "../atoms/Spacer";
import Error from "../atoms/Error";
import useAuthedOnly from '../hooks/useAuthedOnly';
const LSAbrufen2 = () => {
  let authed = useAuthedOnly({});
  let { auftrag } = useParams();

  let [exec, status, result, error] = useApi(
    {
      path: "getls",
      authenticate: true,
    },
    () => {}
  );

  useEffect(() => {
    exec({ auftragId: parseInt(auftrag) });
  }, []);
  return (
    <Layout title="Lieferscheie herunterladen" width={900}>
      {status == "done" && result.lieferscheine.length == 0 && (
        <Error>No results.</Error>
      )}
      {status == "error" && <Error>{error}</Error>}
      {status == "done" && result.lieferscheine.length > 0 && (
        <>
          <Spacer h={8}></Spacer>
          <h1 styleName="lieferschein">Lieferscheine für Auftrag {auftrag}</h1>
          <Spacer h={12}></Spacer>
          {result.lieferscheine
            .sort((a, b) => -1 * a.timestamp.localeCompare(b.timestamp))
            .map((ls) => {
              return (
                <div styleName="lieferschein">
                  <div styleName="name">
                    Lieferschein {ls.lsNr}
                    <div styleName="date">
                      {new Date(ls.timestamp).toLocaleString()}
                    </div>
                  </div>
                  <div styleName="dlButton">
                    <LieferscheinDownloader
                      ls={ls.lsNr}
                    ></LieferscheinDownloader>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </Layout>
  );
};

export default LSAbrufen2;
