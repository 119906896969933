import React from "react";
import Layout from "../atoms/Layout";
import AuftragsInput from "../atoms/AuftragsInput/AuftragsInput";
import { useHistory } from "react-router";
import { Base64 } from "js-base64";
import useAuthedOnly from '../hooks/useAuthedOnly';

const Coils1 = () => {
  let authed = useAuthedOnly({});
  let history = useHistory();
  return (
    <Layout title="Coils abrufen" width={900}>
      <AuftragsInput
        onFinish={(d) => {
          history.push("/coils/" + Base64.encode(JSON.stringify(d)));
        }}
      ></AuftragsInput>
    </Layout>
  );
};

export default Coils1;
