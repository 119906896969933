import React, { useState, useEffect } from "react";
import Layout from "../atoms/Layout/index";
import SuperTable from "../atoms/SuperTable/SuperTable";
import Button from "../atoms/Button/Button";
import { useApi } from "../hooks/useApi";
import Loading from "../atoms/Loading/Loading";
import { blue } from "../lib";
import AuftragsInput from "../atoms/AuftragsInput/AuftragsInput";
function Debug() {
  //groups
  //sort
  //filter
  let [selected, setSelected] = useState([]);
  let [exec, status, result, error] = useApi(
    { path: "lagertestinfo", authenticate: true },
    (r) => {}
  );
  let [exec2, status2, result2, error2] = useApi(
    { path: "userInfo", authenticate: true },
    (r) => {}
  );
  useEffect(() => {
    exec();
    exec2();
  }, []);
  return (
    <Layout title="Debug">
      <h1>lagerInfo</h1>
      <textarea
        style={{ whiteSpace: "pre-wrap", width: 600, height: 300 }}
        value={result && JSON.stringify(result, true, 2)}
      />
      <h1>userInfo</h1>
      <textarea
        style={{ whiteSpace: "pre-wrap", width: 600, height: 300 }}
        value={result2 && JSON.stringify(result2, true, 2)}
      />
    </Layout>
  );
}

export default Debug;
