import React, { useState } from "react";
import style from "./SuperTable.less";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

class SuperTable extends React.Component {
  constructor(props) {
    super(props);
    //generate filter options
    let filterOptions = {};
    props.data.forEach((d) => {
      //for each field:
      props.fields.map((f) => {
        filterOptions[f.field] = [
          ...(filterOptions[f.field] || []),
          d[f.field],
        ].filter(onlyUnique);
      });
    });
    //cluster the data, then sort the clusters.
    this.state = {
      sort: ["_key", "asc"],
      filter: {},
      filterOptions,
      cluster: props.cluster,
      origData: props.data.map((i) => {
        let _key = "";
        if (props.keyExtractor) {
          _key = props.keyExtractor(i);
        } else if (i.id) {
          _key = i.id;
        } else {
          console.log("NO ID FOR TABLE ELEMENTS");
          _key = Math.random();
        }
        return { ...i, _key };
      }),
      data: [],
    };
  }

  componentDidMount() {
    this.applyAll();
  }

  applyAll(reset) {
    let a = this.state.origData;
    a = this.applyFilter(a);
    a = this.applyCluster(a);
    a = this.applySort(a);
    this.setState({ data: a }, () => {
      if (reset) {
        this.props.onChange([]);
      }
    });
  }
  compare(a, b) {
    if (typeof a == "string") {
      return a.localeCompare(b);
    }
    return a - b;
  }

  applySort(arr) {
    return arr.map((i) => {
      return {
        ...i,
        data: i.data.sort(
          (a, b) =>
            this.compare(a[this.state.sort[0]], b[this.state.sort[0]]) *
            (this.state.sort[1] == "desc" ? -1 : 1)
        ),
      };
    });
  }
  applyFilter(arr) {
    let filterFields = Object.keys(this.state.filter);
    return arr.filter((r) => {
      let isValid = false;
      isValid = filterFields.every((ff) => {
        let filterVal = this.state.filter[ff];
        if (filterVal !== "[[[null]]]") {
          if (r[ff] != filterVal) {
            return false;
          }
        }

        return true;
      });

      return isValid;
    });
  }
  setFilter(f) {
    this.setState({ filter: f }, () => {
      this.applyAll(true);
    });
  }
  setSort(s) {
    if (s[0] == this.state.sort[0] && s[1] == this.state.sort[1]) {
      //todo set default sort
      this.setState({ sort: [] }, () => {
        this.applyAll();
      });
    } else {
      this.setState({ sort: s }, () => {
        this.applyAll();
      });
    }
  }
  applyCluster(arr) {
    let { cluster } = this.state;
    // create the following array:
    // [{...cluster, data:[]},{...cluster, data:[]}]
    let data = {};
    arr.forEach((r) => {
      data[r[cluster]] = [...(data[r[cluster]] || []), r];
    });
    let a = [];
    Object.keys(data).forEach((k) => {
      let v = data[k];
      let sums = {};
      v.forEach((r) => {
        this.props.fields.map((f) => {
          if (f.summable) {
            sums[f.field] = (sums[f.field] || 0) + r[f.field];
          }
        });
      });
      let c = {
        field: cluster,
        value: k,
        sums,
        data: v,
      };
      a.push(c);
    });
    return a.sort((x, y) => this.compare(x.value, y.value));
  }

  render() {
    let { filter, sort, filterOptions, data } = this.state;
    let {
      fields,
      value,
      onChange,
      showSummary,
      hideHeader,
      disabled,
    } = this.props;

    return (
      <div styleName={"TableWrapper"}>
        <table cellSpacing="0" styleName={"SuperTable"}>
          {!hideHeader && (
            <TableHeader
              fields={fields}
              setFilter={(f) => this.setFilter(f)}
              filter={filter}
              sort={sort}
              setSort={(s) => this.setSort(s)}
              filterOptions={filterOptions}
            ></TableHeader>
          )}
          <tbody>
            {data.map((c, i) => {
              return (
                <TableCluster
                  key={i}
                  showSummary={showSummary}
                  value={value}
                  disabled={disabled}
                  onChange={onChange}
                  data={c}
                  fields={fields}
                ></TableCluster>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SuperTable;

/*
cluster summary
*/

function TableCluster({
  data,
  fields,
  value = [],
  onChange,
  showSummary,
  disabled = [],
}) {
  let allIds = data.data.map((d) => d._key);
  let isAllChecked = allIds
    .filter((id) => !disabled.includes(id))
    .every((i) => value.includes(i));
  return (
    <>
      {data.data.map((r, i) => {
        return (
          <TableRow
            key={i}
            selected={value.includes(r._key)}
            disabled={disabled.includes(r._key)}
            onChange={(b) => {
              //if true, add id, else remove
              if (b) {
                onChange([...value, r._key]);
              } else {
                onChange(value.filter((i) => i !== r._key));
              }
            }}
            fields={fields}
            data={r}
          ></TableRow>
        );
      })}
      {showSummary && (
        <tr styleName={"TableCluster"}>
          <td style={{ width: 20, paddingTop: 4, paddingBottom: 4 }}>
            <input
              onChange={() =>
                isAllChecked
                  ? onChange(value.filter((v) => !allIds.includes(v)))
                  : onChange(
                      [
                        ...value,
                        ...allIds.filter((id) => !disabled.includes(id)),
                      ].filter(onlyUnique)
                    )
              }
              checked={isAllChecked}
              type="checkbox"
            ></input>
          </td>
          {fields.map((f, i) => {
            if (i === 0) {
              return (
                <td key={i} styleName={"cell"} style={{ flex: 1 }}>
                  ~
                </td>
              );
            }
            if (f.summable) {
              return (
                <td
                  key={i}
                  styleName={"cell"}
                  style={{ flex: 1, fontWeight: "bold" }}
                >
                  {f.seperator
                    ? numberWithCommas(data.sums[f.field])
                    : data.sums[f.field]}
                </td>
              );
            }
            return <td key={i} styleName={"cell"} style={{ flex: 1 }}></td>;
          })}
        </tr>
      )}
    </>
  );
}
function TableRow({ fields, data, selected, onChange, disabled = false }) {
  return (
    <tr
      styleName={"TableRow " + (disabled ? "disabled " : " ")}
      onClick={() => !disabled && onChange(!selected)}
    >
      <td>
        <input
          checked={selected}
          onChange={() => !disabled && onChange(!selected)}
          type={"checkbox"}
        ></input>
      </td>
      {fields.map((f, i) => {
        return (
          <td key={i} styleName={"cell"}>
            {f.seperator ? numberWithCommas(data[f.field]) : data[f.field]}
          </td>
        );
      })}
    </tr>
  );
}

function numberWithCommas(x) {
  return x ? x.toLocaleString() : "";
}

function TableHeader({
  fields,
  filter,
  sort,
  setSort,
  filterOptions,
  setFilter,
}) {
  return (
    <thead>
      <tr styleName={"TableHeader"}>
        <th styleName="cell"></th>

        {fields.map((f, i) => {
          let isSortAsc = sort[0] == f.field && sort[1] == "asc";
          let isSortDesc = sort[0] == f.field && sort[1] == "desc";
          return (
            <th key={i} styleName={"cell"}>
              <div style={{ display: "flex", marginBottom: 4 }}>
                {f.name}
                {f.sort && (
                  <div style={{ display: "flex", marginLeft: 4 }}>
                    <div
                      styleName={"sortButton"}
                      onClick={() => {
                        setSort([f.field, "asc"]);
                      }}
                      style={{
                        background: isSortAsc ? "#002c76" : "white",
                        color: isSortAsc ? "white" : "inherit",
                      }}
                    >
                      <FaCaretDown></FaCaretDown>
                    </div>
                    <div
                      onClick={() => {
                        setSort([f.field, "desc"]);
                      }}
                      styleName={"sortButton"}
                      style={{
                        background: isSortDesc ? "#002c76" : "white",
                        color: isSortDesc ? "white" : "inherit",
                      }}
                    >
                      <FaCaretUp></FaCaretUp>
                    </div>
                  </div>
                )}
              </div>
              {f.filter && (
                <ColFilter
                  value={filter[f.field]}
                  onChange={(v) => setFilter({ ...filter, [f.field]: v })}
                  options={filterOptions[f.field]}
                ></ColFilter>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function ColFilter({ value, onChange, options = [] }) {
  return (
    <div>
      <select
        styleName="ColFilter"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value={"[[[null]]]"}></option>
        {options.map((o) => (
          <option value={o}>{o ? o : "[Leer]"}</option>
        ))}
      </select>
    </div>
  );
}
